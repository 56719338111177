<template>
  <div class="container px-md-0 grids">
    <div class="row">
      <Grid
        class="col-md-4 col-6"
        title="End-to-End Workflow Automation "
        :img="
          require('../../assets/immigration/end-to-end-workflow-automation.png')
        "
        hideDetail="true"
        link="end-to-end-workflow-automation"
      >
        <span class="grid-content-home"
          >Start cases quickly and correctly, and manage them efficiently every
          step of the way.</span
        >
      </Grid>
      <Grid
        class="col-md-4 col-6"
        title="Document Management"
        :img="require('../../assets/immigration/document-management.png')"
        hideDetail="true"
        link="document-management"
      >
        <span class="grid-content-home"
          >Turn tedious tasks into collaborative and automated processes.</span
        >
      </Grid>
      <Grid
        class="col-md-4 col-6"
        title="Automated Case Management"
        :img="require('../../assets/immigration/automated-case-management.png')"
        hideDetail="true"
        link="automated-case-management"
      >
        <span class="grid-content-home"
          >Leverage technology to streamline and organize your firm.</span
        >
      </Grid>
      <Grid
        class="col-md-4 col-6"
        title="Intelligent Information Storage"
        :img="
          require('../../assets/immigration/intelligent-information-storage.png')
        "
        hideDetail="true"
        link="intelligent-information-storage"
      >
        <span class="grid-content-home"
          >Reduce redundancies and save time.</span
        >
      </Grid>
      <Grid
        class="col-md-4 col-6"
        title="DS-160/260 E-Filing"
        :img="require('../../assets/immigration/ds160-260-auto-filing.png')"
        hideDetail="true"
        link="ds160-260-auto-filing"
      >
        <span class="grid-content-home"
          >The only legal platform that prevents system crashes while e-filing
          these forms.</span
        >
      </Grid>
      <Grid
        class="col-md-4 col-6"
        title="Business Management"
        :img="require('../../assets/immigration/business-management.png')"
        hideDetail="true"
        link="business-management"
      >
        <span class="grid-content-home"
          >Improve client experiences and help your business grow.</span
        >
      </Grid>
    </div>
  </div>
</template>

<script>
import Grid from '@/components/Block/Grid.vue'

export default {
  components: { Grid },
}
</script>

<style scoped>
@media screen and (min-width: 1101px) {
  .grids {
    margin-top: -12%;
  }
}
</style>
