<template>
  <div class="split-view">
    <slot></slot>
  </div>
</template>

<script lang="ts">
import Vue from 'vue'

export default Vue.extend({})
</script>

<style lang="less" scoped>
.split-view {
  display: flex;
  align-items: stretch;

  @media only screen and (max-width: 770px) {
    flex-direction: column;

    > * + * {
      margin-top: 14px;
    }
  }

  @media only screen and (min-width: 771px) {
    > * + * {
      margin-left: 6%;
    }
  }

  > * {
    flex: 1;
  }
}
</style>
