<template>
  <div class="immigration gray-bg">
    <hero-immigration />

    <grids />

    <split-view id="end-to-end-workflow-automation" class="main-box">
      <div
        :style="{
          backgroundImage: `url(${require('../../assets/immigration/end-to-end-workflow-automation.png')})`,
        }"
        alt="End-to-End Workflow Automation"
        class="image"
      />

      <div class="main-content">
        <h2>End-to-End Workflow Automation</h2>
        <p>
          New hires (and seasoned attorneys) make mistakes. But forgetting a key
          step or working too slowly can have serious consequences. Eliminate
          mistakes with Neutrinet’s form library and simple case setup. Your
          team can get to work on the right task in minutes.
        </p>

        <ul class="list">
          <li>Extensive Case Library</li>
          <li>Intuitive Client Intake</li>
          <li>One-Click HR Reporting</li>
          <li>Smart USCIS Forms Filing</li>
        </ul>

        <consultation-button
          text="Learn More"
          link="https://www.immigrationlaw.ai/activity/ailaw/demo"
        />
      </div>
    </split-view>

    <split-view id="document-management" class="main-box blue-bg">
      <div class="main-content">
        <h2>Document Management</h2>
        <p>
          Collecting case documents from clients is challenging - and finding
          those documents internally can be even more frustrating. Neutrinet
          turns those tedious tasks into automated processes and organizes your
          files for easy retrieval.
        </p>

        <ul class="list">
          <li>Automated Document Collection</li>
          <li>Intuitive Document Compilation</li>
          <li>Keyword Search Archives</li>
        </ul>
        <consultation-button
          text="Learn More"
          link="https://www.immigrationlaw.ai/activity/ailaw/demo"
        />
      </div>

      <div
        :style="{
          backgroundImage: `url(${require('../../assets/immigration/document-management.png')})`,
        }"
        alt="Document Management"
        class="image"
      />
    </split-view>

    <split-view id="automated-case-management" class="main-box">
      <div
        :style="{
          backgroundImage: `url(${require('../../assets/immigration/automated-case-management.png')})`,
        }"
        alt="Automated Case Management"
        class="image"
      />

      <div class="main-content">
        <h2>Automated Case Management</h2>
        <p>
          Scaling case loads can be challenging. Sticky notes, white boards, and
          excel files won’t keep everything straight when things get busy.
          Attorneys need a tool that can keep up. Neutrinet uses smart
          technology and legal savvy to power efficient practices.
        </p>
        <ul class="list">
          <li>Case Status Tracking</li>
          <li>Case Management</li>
          <li>Task Reminders</li>
          <li>Automated USCIS status updates</li>
        </ul>
        <consultation-button
          text="Learn More"
          link="https://www.immigrationlaw.ai/activity/ailaw/demo"
        />
      </div>
    </split-view>

    <split-view id="intelligent-information-storage" class="main-box blue-bg">
      <div class="main-content">
        <h2>Intelligent Information Storage</h2>
        <p>
          Human error happens. Clients forget. Lawyers get busy. Neutrinet has
          your back and frees you from the repetitive workload with powerful
          automations, reminders, and auto fill functions to make sure nothing
          gets missed.
        </p>
        <ul class="list">
          <li>Automated Reuse of Information Across Documents</li>
          <li>Report Generation</li>
        </ul>
        <consultation-button
          text="Learn More"
          link="https://www.immigrationlaw.ai/activity/ailaw/demo"
        />
      </div>

      <div
        :style="{
          backgroundImage: `url(${require('../../assets/immigration/intelligent-information-storage.png')})`,
        }"
        alt="Automated Case Management"
        class="image"
      />
    </split-view>

    <split-view id="ds160-260-auto-filing" class="main-box">
      <div
        :style="{
          backgroundImage: `url(${require('../../assets/immigration/ds160-260-auto-filing.png')})`,
        }"
        alt="DS-160/260 Auto-Filing"
        class="image"
      />

      <div class="main-content">
        <h2>DS-160/260 Auto-Filing</h2>

        <p>
          Neutrinet is the only legal platform that prevents system crashes on
          the CEAC website while e-filing DS-160/260 forms. It’s simple, user
          friendly, and gets the job done.
        </p>

        <ul class="list">
          <li>DS-160/260 Auto-Filing</li>
        </ul>

        <consultation-button
          text="Learn More"
          link="https://www.immigrationlaw.ai/activity/ailaw/demo"
        />
      </div>
    </split-view>

    <split-view id="business-management" class="main-box blue-bg">
      <div class="main-content">
        <h2>Business Management</h2>
        <p>
          Happy clients help law firms grow. With Neutrinet, clients experience
          easy onboarding, clear communication, and a seamless user experience -
          allowing you to focus on client service, and growing your bottom line.
        </p>

        <ul class="list">
          <li>Integrations (LawPay, Clio, and more!)</li>
          <li>Intuitive Client Portal</li>
          <li>Built-In Referral and Review Programs</li>
        </ul>

        <consultation-button
          text="Learn More"
          link="https://www.immigrationlaw.ai/activity/ailaw/demo"
        />
      </div>

      <div
        :style="{
          backgroundImage: `url(${require('../../assets/immigration/business-management.png')})`,
        }"
        alt="Business Management"
        class="image"
      />
    </split-view>

    <immigration-content />
  </div>
</template>

<script lang="ts">
import Vue from 'vue'
import HeroImmigration from './HeroImmigration.vue'
import ImmigrationContent from './ImmigrationContent.vue'
import SplitView from '../../components/SplitView.vue'
import Grids from './grids.vue'
import ConsultationButton from '@/components/Block/ConsultationButton.vue'

export default Vue.extend({
  components: {
    HeroImmigration,
    ImmigrationContent,
    SplitView,
    Grids,
    ConsultationButton,
  },
})
</script>

<style lang="less" scoped>
.immigration {
  background: white;
}

.image {
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  flex-basis: 200px;
}

.main-box {
  padding: 50px 10%;
}

.main-content {
  padding: 8% 0;

  > h2,
  > h3 {
    color: #000;
    font-weight: 800;
    font-family: Montserrat, sans-serif;
    margin-bottom: 1rem;
  }

  > h2 {
    font-size: 32px;
  }

  > h3 {
    font-size: 24px;
  }

  > p {
    font-family: 'Montserrat', sans-serif;
    /*font-size: 20px;*/
    line-height: 24px;
    text-rendering: optimizeLegibility;
  }
}

.gray-bg {
  /*background: rgb(206 215 231);*/
  background: #f0f0f0;
}

.blue-bg {
  background: #ced7e6;
}

.list {
  padding-left: 0;
  list-style: inside;
}
</style>
