<template>
  <div>
    <section class="block3">
      <CollapseShow white :info="blocks[2]" ref="block3" key="3" />
    </section>
    <!-- Testimonial -->
    <h2 class="bg-title text-center font-weight-bolder">Testimonials</h2>
    <div class="container margin-testimonial testdisplay" id="testdisplay">
      <Testimonial @slideMove="slideMove">
        <Comment :img="left.img" :title="left.title" slot="left">
          {{ left.desc }}
        </Comment>
        <Comment :img="middle.img" :title="middle.title" slot="middle">
          {{ middle.desc }}
        </Comment>
        <Comment :img="right.img" :title="right.title" slot="right">
          {{ right.desc }}
        </Comment>
      </Testimonial>
    </div>
    <div
      id="carouselTestControls"
      class="container carousel slide"
      data-ride="carousel"
    >
      <div class="carousel-inner">
        <div
          class="carousel-item"
          :class="{ active: index == 0 }"
          v-for="(slide, index) in slides"
          :key="index"
        >
          <Comment
            :img="slide.img"
            :title="slide.title"
            class="immigration-slide-box"
          >
            {{ slide.desc }}
          </Comment>
        </div>
      </div>
      <a
        class="carousel-control-prev"
        href="#carouselTestControls"
        role="button"
        data-slide="prev"
      >
        <span class="carousel-control-prev-icon" aria-hidden="true"></span>
        <span class="sr-only">Previous</span>
      </a>
      <a
        class="carousel-control-next"
        href="#carouselTestControls"
        role="button"
        data-slide="next"
      >
        <span class="carousel-control-next-icon" aria-hidden="true"></span>
        <span class="sr-only">Next</span>
      </a>
    </div>
    <!-- pricing  -->
    <section>
      <h2 class="bg-title font-weight-bolder pricing-title">PRICING</h2>
      <div class="pricing">
        <div class="container">
          <ul class="pricing-wrap row">
            <li
              class="col-lg-4 col-md-4 col-xs-12 pricing-cube"
              v-for="(cube, p) in prices"
              :key="p"
            >
              <div>
                <p>{{ cube.title }}</p>
                <ul class="cube-lists">
                  <table>
                    <tr :key="index" v-for="(list, index) in cube.lists">
                      <td width="60" style="text-align: right">
                        <i class="checkCircle"></i>
                      </td>
                      <td class="table-right">{{ list }}</td>
                    </tr>
                    <!-- <tr>
                      <td width="60" style="text-align: right">
                        <em v-if="cube.lists[2][0]">{{ cube.lists[2][0] }}</em>
                        <i class="checkCircle" v-if="!cube.lists[2][0]"></i>
                      </td>
                      <td class="table-right">{{ cube.lists[2][1] }}</td>
                    </tr> -->
                  </table>

                  <!-- <li :key="index" v-for="(list, index) in cube.lists">{{list}}</li> -->
                </ul>
                <i class="line"></i>
                <div class="price">
                  <span v-if="!cube.noprice">
                    <i>$</i><i>{{ cube.price[0] }}</i
                    ><i>/{{ cube.price[1] }}</i>
                  </span>
                  <a
                    v-else
                    target="_blank"
                    href="https://www.immigrationlaw.ai/activity/ailaw/demo"
                    >{{ cube.price[0] }}</a
                  >
                </div>
                <div class="price-hint" v-if="cube.price.length > 1">
                  Annual Subscription
                </div>
                <div
                  class="price-hint"
                  :style="{ visibility: p === 0 ? 'visible' : 'hidden' }"
                >
                  + <span>$99</span> one-time setup fee
                </div>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </section>
  </div>
</template>

<script lang="ts">
import CollapseShow from '../../components/Block/CollapseShow.vue'
import Testimonial from '../../components/Block/Testimonial.vue'
import Comment from '../../components/Block/Comment.vue'
const blocks = [
  {
    items: [
      {
        active: true,
        title: 'Form Automation',
        desc:
          'Auto-populate multiple forms through online smart questionnaires.',
        type: 'img',
        src: require('../../assets/immigration/form_automation.gif'),
      },
      {
        active: false,
        title: 'Document Management',
        desc:
          'Access case files and interact with clients from cloud-based system anywhere anytime.',
        type: 'img',
        src: require('../../assets/immigration/document_management.gif'),
      },
      {
        active: false,
        title: 'Task Customization',
        desc:
          'Design recurring tasks from templates for dedicated participants.',
        type: 'img',
        src: require('../../assets/immigration/task_customization.gif'),
      },
    ],
  },
  {
    items: [
      {
        active: true,
        title: 'E-filing',
        desc: 'E-file DS-160 forms automatically on CEAC',
        type: 'img',
        src: require('../../assets/immigration/e-filing.gif'),
      },
      {
        active: false,
        title: 'Compilation',
        desc:
          'Drag and drop to organize documents, insert page breakers before printing out.',
        type: 'img',
        src: require('../../assets/immigration/compilation.gif'),
      },
      {
        active: false,
        title: 'Status Tracking',
        desc: 'Sync and notify clients of real-time case status.',
        type: 'img',
        src: require('../../assets/immigration/status_tracking.gif'),
      },
    ],
  },
  {
    items: [
      {
        active: true,
        title: 'Intuitive UI in Multiple Languages (Current 18 languages)',
        desc: 'All UI and questionnaires are available in multiple languages. ',
        type: 'img',
        src: require('../../assets/immigration/multiple-languages.png'),
      },
      {
        active: false,
        title: 'Level of Access',
        desc:
          'Set different roles for team members and set levels of case accessibilities ',
        type: 'img',
        src: require('../../assets/immigration/level-of-access.png'),
      },
      {
        active: false,
        title: 'Mobile Optimization and iOS App',
        desc:
          'Fully optimized for any mobile devices. iOS App available on the App Store. Prepare immigration cases anytime and anywhere.',
        type: 'img',
        src: require('../../assets/immigration/app.png'),
      },
    ],
  },
]
const slides = [
  {
    img: require('../../assets/partner/yclaw.png'),
    title: 'YC Law Group',
    desc:
      'After integrating our company’s cases into Neutrinet’s software, I’ve noticed a drastic improvement in efficiency. Clients are happy to forgo long email chains, clerks here save time previously spent manually filling in forms, and attorneys can take on more cases. I couldn’t be happier with our experience with Neutrinet.',
  },
  {
    img: require('../../assets/partner/lydlaw.png'),
    title: 'LYD Law',
    desc:
      'Transparency, Automation, Cost-Efficiency. That’s exactly what Neutrinet has provided us with: a platform not only easing the communication between clients and attorneys but simplifying this procedure for all parties involved. This is a service that embodies the saying: “Money Well Spent”.',
  },
  {
    img: require('../../assets/partner/sunwiselaw.png'),
    title: 'Sunwise Law',
    desc: `Document submission and compilation should be intuitive, not just for the sake of clients but for law firms as well. One of our largest issues prior to utilizing Neutrinet’s platform was organizing documents for submissions, a problem perfectly addressed by the automation technology present here. We’ve saved countless hours, and I look forward to continuing our partnership.`,
  },
  {
    img: require('../../assets/partner/dtlaw.jpeg'),
    title: 'D & T Law Group',
    desc: `This is an extremely accessible and price friendly software.It is useful for those immigration lawyers who wish to save their valuable time on filing out forms. The best part of this software is it’s ability to manage different clients documents instantly and simultaneously. It can quickly help put together a visa packet within minutes. Lastly, you will never lose track of your clients documents when using this software.`,
  },
  {
    img: require('../../assets/partner/deheng.png'),
    title: 'DeHeng Law Offices',
    desc: `Our experience with Neutrinet support team was extraordinary. We are a lot more efficient and have better transparency with clients because of Neutrinet's software. Our team members absolutely love the Worksheets and prepare all cases and forms in Neutrinet.`,
  },
]
const prices = [
  {
    title: 'Standard Service',
    lists: [
      'Unlimited Cases, Storage',
      'Logo and URL customization',
      'LawPay Integration',
      'Dedicated live support specialist',
      'iOS App',
      'Price includes all upcoming updates and features',
    ],
    price: ['3000', 'year'],
  },
  {
    title: 'Premium Service',
    lists: [
      'Everything in Standard Service',
      'Advanced AI features',
      'Access to Admin panel',
      'Multiple logins',
      'Dedicated tech support',
    ],
    price: ['6000', 'year'],
  },
  {
    title: 'Enterprise',
    lists: [
      'Everything in Premium Service',
      'Prioritized feature request',
      'Optimized data processing solution',
      'End-to-end tech support',
      'User training program',
    ],
    price: ['Get Quote'],
    noprice: true,
  },
]
export default {
  components: { CollapseShow, Testimonial, Comment },
  data() {
    return {
      currentSlideIndex: 0,
      prices,
      blocks,
      slides,
      left: slides[0],
      middle: slides[1],
      right: slides[2],
    }
  },
  created() {
    this.isMobile = false
  },
  mounted() {
    this.addScrollListen()
  },
  beforeDestroy() {
    window.onscroll = null
    window.onresize = null
  },
  methods: {
    slideMove(direction, slotName) {
      let len = this.slides.length - 1
      if (direction === 'left') {
        if (len > this.currentSlideIndex) {
          this.currentSlideIndex++
        } else {
          this.currentSlideIndex = 0
        }
        this[slotName] = this.slides[this.currentSlideIndex]
      } else if (direction === 'right') {
        if (this.currentSlideIndex === 0) {
          this.currentSlideIndex = len
        } else {
          this.currentSlideIndex--
        }
        this[slotName] = this.slides[this.currentSlideIndex]
      }
    },
    isInscreen(ele) {
      if (ele == undefined) return false
      // 元素距离可视窗口的顶部的距离
      let boxTop = ele.getBoundingClientRect().top
      // 窗口的总高度
      let screenHeight = window.screen.availHeight
      if (boxTop / screenHeight < 0.8) {
        return true
      }
      return false
    },
    addScrollListen() {
      const block3 = this.$refs.block3.$el

      window.onscroll = () => {
        ;[block3].forEach(dom => {
          if (this.isInscreen(dom) && !dom.classList.contains('slideUp')) {
            dom.classList.add('slideUp')
          }
        })
      }
    },
  },
}
</script>

<style scoped lang="scss">
div.immigration-slide-box {
  height: 550px;
}
.carousel-item {
  .box {
    margin: 0 auto;
    background-color: #fff;
  }
}
#carouselTestControls {
  display: none;
}
ul {
  list-style: none;
  padding: 0;
}

em {
  font-style: normal;
  color: #304881;
  font-size: 20px;
}

.table-right {
  text-align: left;
  padding-left: 10px;
}

.checkCircle {
  vertical-align: middle;
  display: inline-block;
  width: 14px;
  height: 14px;
  background: url(../../assets/immigration/checkcircle.png);
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
}

.bg-title {
  margin-top: 60px;
}

.slide-wrap {
  margin: 100px auto;
  position: relative;
}

.slide-box {
  color: #464c56;
  transition: all 0.3s linear;
  overflow-y: auto;
  height: 100%;

  h4 {
    font-size: 20px;
    margin-bottom: 40px;
  }

  p {
    color: #464c56;
  }
}
.block3 {
  background: url(../../assets/immigration/middle.png);
  background-size: 100% 100%;
  background-repeat: no-repeat;
  padding: 40px 0 40px;
  position: relative;
}

.block3:before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 150px;
  height: 300px;
  background: url(../../assets/immigration/circle.png);
  background-size: 100% auto;
  background-repeat: no-repeat;
}

ul {
  list-style: none;
}

.bg-title {
  color: #304881;
  font-size: 28px;
  text-align: center;
  margin: 30px auto;
  text-transform: uppercase;
}

.cube-lists {
  font-size: 14px;
  margin: 40px auto;
  height: 160px;
}

.line {
  display: block;
  height: 1px;
  border-top: 1px solid #d8d8d8;
}

.price-hint {
  font-size: 14px;
  color: #657180;

  span {
    color: #f8822b;
  }
}

.pricing {
  background: url(../../assets/immigration/bottom.png);
  background-size: 100% 100%;
  background-repeat: no-repeat;
  margin-bottom: -1px;
  padding: 30px 0 60px;

  .pricing-hint {
    font-size: 14px;
    color: #fff;
  }

  .price {
    overflow: hidden;
    margin-top: 30px;
    color: #f8822b;
    line-height: 42px;

    i:first-child {
      font-size: 18px;
      vertical-align: super;
    }

    i:nth-child(2) {
      font-size: 28px;
    }

    i:last-child {
      font-size: 14px;
      color: #657180;
    }

    a {
      margin-bottom: 10px;
      display: inline-block;
      background-color: #f8822b;
      color: #fff;
      border-radius: 3px;
      transition: all 0.3s linear;
      height: 32px;
      line-height: 32px;
      text-align: center;
      font-size: 18px;
      padding: 0px 16px;
    }

    a:hover {
      text-decoration: none;
      color: #fff;
      background-image: linear-gradient(to bottom right, #f76b1c, #f9b54a);
    }
  }
}

.pricing-cube {
  margin: 8px 0;
  transition: all 0.3s linear;
}

.pricing-cube:hover {
  transform: translateY(-3px) scale(1.01);
}

.pricing-cube > div {
  box-sizing: border-box;
  height: 100%;

  p {
    text-transform: uppercase;
  }
}

.pricing-wrap {
  li {
    cursor: pointer;
    padding: 0px 8px;
    text-align: center;

    > div {
      box-shadow: 4px 0 8px rgba(0, 0, 0, 0.2);
      padding: 20px 0;
      border-radius: 8px;
      background-color: #fff;

      i {
        font-style: normal;
      }
    }
  }
}

.CollapseShow {
  margin: 100px auto;
}

.slide {
  display: none;
}
@media screen and (max-width: 1023px) {
  .testdisplay {
    display: none;
  }
  #carouselTestControls {
    display: block;
    .carousel-control-prev,
    .carousel-control-next {
      span {
        background-color: rgba(0, 0, 0, 0.5);
        border-radius: 50%;
        width: 30px;
        height: 30px;
        background-size: 50% auto;
      }
    }
  }
  .slide {
    display: inline-block;
  }
}

@media (max-width: 577px) {
  .smallgrid {
    padding: 1px;
  }
}

@media (max-width: 850px) {
  section.block3 {
    background-size: cover;
    background-position: center;
  }

  .CollapseShow {
    margin: 60px auto;
  }

  div.pricing {
    background-size: cover;
  }

  .slide-wrap:before {
    top: -60px;
    left: 0;
    content: '';
    width: 60px;
    height: 60px;
  }

  .slide-wrap:after {
    bottom: -60px;
    right: 0;
    content: '';
    width: 60px;
    height: 60px;
  }
}

.casual-div {
  background-color: $theme-grid-backgroud-color;
}

.grid-selected {
  background-color: $theme-grid-backgroud-color;
}

.board-selected {
  min-height: 24rem;
  background-color: $theme-grid-backgroud-color;
  color: white;
}

.padding-right-zero {
  padding-right: 0px;
}

.padding-x-zero {
  padding-left: 0px;
  padding-right: 0px;
}

.padding-left-zero {
  padding-left: 0px;
}

.flex-container {
  display: flex;
  justify-content: center;
}

.margin-service {
  margin-top: 80px;
  margin-bottom: 80px;
  padding-top: 80px;
  padding-bottom: 80px;
  background: url('../../assets/nxt/services_bg.png');
  background-size: cover;
}

.margin-service-title {
  margin-bottom: 50px;
  color: white;
}

button:focus {
  outline: 0;
}

.switch-left {
  height: 55px;
  width: 150px;
  background-color: #ffffff;
  border: none;
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
  font-weight: bolder;
  color: #848384;
  transition: 0.5s;
}

.switch-left-selected {
  height: 55px;
  width: 150px;
  background-color: #e5e4e5;
  border: none;
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
  font-weight: bolder;
  color: #454445;
}

.switch-right {
  height: 55px;
  width: 150px;
  background-color: #ffffff;
  border: none;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
  font-weight: bolder;
  color: #848384;
  transition: 0.5s;
}

.switch-right-selected {
  height: 55px;
  width: 150px;
  background-color: #e5e4e5;
  border: none;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
  font-weight: bolder;
  color: #454445;
}

@keyframes slideUp {
  0% {
    opacity: 0.2;
    position: relative;
    top: 100px;
  }

  50% {
    opacity: 0.5;
    position: relative;
    top: 50px;
  }

  100% {
    opacity: 1;
    position: relative;
    top: 0;
  }
}

.slideUp {
  animation-name: slideUp;
  animation-duration: 0.7s;
  animation-timing-function: ease-in-out;
}
</style>
