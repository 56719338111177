var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"immigration gray-bg"},[_c('hero-immigration'),_c('grids'),_c('split-view',{staticClass:"main-box",attrs:{"id":"end-to-end-workflow-automation"}},[_c('div',{staticClass:"image",style:({
        backgroundImage: ("url(" + (require('../../assets/immigration/end-to-end-workflow-automation.png')) + ")"),
      }),attrs:{"alt":"End-to-End Workflow Automation"}}),_c('div',{staticClass:"main-content"},[_c('h2',[_vm._v("End-to-End Workflow Automation")]),_c('p',[_vm._v("\n        New hires (and seasoned attorneys) make mistakes. But forgetting a key\n        step or working too slowly can have serious consequences. Eliminate\n        mistakes with Neutrinet’s form library and simple case setup. Your\n        team can get to work on the right task in minutes.\n      ")]),_c('ul',{staticClass:"list"},[_c('li',[_vm._v("Extensive Case Library")]),_c('li',[_vm._v("Intuitive Client Intake")]),_c('li',[_vm._v("One-Click HR Reporting")]),_c('li',[_vm._v("Smart USCIS Forms Filing")])]),_c('consultation-button',{attrs:{"text":"Learn More","link":"https://www.immigrationlaw.ai/activity/ailaw/demo"}})],1)]),_c('split-view',{staticClass:"main-box blue-bg",attrs:{"id":"document-management"}},[_c('div',{staticClass:"main-content"},[_c('h2',[_vm._v("Document Management")]),_c('p',[_vm._v("\n        Collecting case documents from clients is challenging - and finding\n        those documents internally can be even more frustrating. Neutrinet\n        turns those tedious tasks into automated processes and organizes your\n        files for easy retrieval.\n      ")]),_c('ul',{staticClass:"list"},[_c('li',[_vm._v("Automated Document Collection")]),_c('li',[_vm._v("Intuitive Document Compilation")]),_c('li',[_vm._v("Keyword Search Archives")])]),_c('consultation-button',{attrs:{"text":"Learn More","link":"https://www.immigrationlaw.ai/activity/ailaw/demo"}})],1),_c('div',{staticClass:"image",style:({
        backgroundImage: ("url(" + (require('../../assets/immigration/document-management.png')) + ")"),
      }),attrs:{"alt":"Document Management"}})]),_c('split-view',{staticClass:"main-box",attrs:{"id":"automated-case-management"}},[_c('div',{staticClass:"image",style:({
        backgroundImage: ("url(" + (require('../../assets/immigration/automated-case-management.png')) + ")"),
      }),attrs:{"alt":"Automated Case Management"}}),_c('div',{staticClass:"main-content"},[_c('h2',[_vm._v("Automated Case Management")]),_c('p',[_vm._v("\n        Scaling case loads can be challenging. Sticky notes, white boards, and\n        excel files won’t keep everything straight when things get busy.\n        Attorneys need a tool that can keep up. Neutrinet uses smart\n        technology and legal savvy to power efficient practices.\n      ")]),_c('ul',{staticClass:"list"},[_c('li',[_vm._v("Case Status Tracking")]),_c('li',[_vm._v("Case Management")]),_c('li',[_vm._v("Task Reminders")]),_c('li',[_vm._v("Automated USCIS status updates")])]),_c('consultation-button',{attrs:{"text":"Learn More","link":"https://www.immigrationlaw.ai/activity/ailaw/demo"}})],1)]),_c('split-view',{staticClass:"main-box blue-bg",attrs:{"id":"intelligent-information-storage"}},[_c('div',{staticClass:"main-content"},[_c('h2',[_vm._v("Intelligent Information Storage")]),_c('p',[_vm._v("\n        Human error happens. Clients forget. Lawyers get busy. Neutrinet has\n        your back and frees you from the repetitive workload with powerful\n        automations, reminders, and auto fill functions to make sure nothing\n        gets missed.\n      ")]),_c('ul',{staticClass:"list"},[_c('li',[_vm._v("Automated Reuse of Information Across Documents")]),_c('li',[_vm._v("Report Generation")])]),_c('consultation-button',{attrs:{"text":"Learn More","link":"https://www.immigrationlaw.ai/activity/ailaw/demo"}})],1),_c('div',{staticClass:"image",style:({
        backgroundImage: ("url(" + (require('../../assets/immigration/intelligent-information-storage.png')) + ")"),
      }),attrs:{"alt":"Automated Case Management"}})]),_c('split-view',{staticClass:"main-box",attrs:{"id":"ds160-260-auto-filing"}},[_c('div',{staticClass:"image",style:({
        backgroundImage: ("url(" + (require('../../assets/immigration/ds160-260-auto-filing.png')) + ")"),
      }),attrs:{"alt":"DS-160/260 Auto-Filing"}}),_c('div',{staticClass:"main-content"},[_c('h2',[_vm._v("DS-160/260 Auto-Filing")]),_c('p',[_vm._v("\n        Neutrinet is the only legal platform that prevents system crashes on\n        the CEAC website while e-filing DS-160/260 forms. It’s simple, user\n        friendly, and gets the job done.\n      ")]),_c('ul',{staticClass:"list"},[_c('li',[_vm._v("DS-160/260 Auto-Filing")])]),_c('consultation-button',{attrs:{"text":"Learn More","link":"https://www.immigrationlaw.ai/activity/ailaw/demo"}})],1)]),_c('split-view',{staticClass:"main-box blue-bg",attrs:{"id":"business-management"}},[_c('div',{staticClass:"main-content"},[_c('h2',[_vm._v("Business Management")]),_c('p',[_vm._v("\n        Happy clients help law firms grow. With Neutrinet, clients experience\n        easy onboarding, clear communication, and a seamless user experience -\n        allowing you to focus on client service, and growing your bottom line.\n      ")]),_c('ul',{staticClass:"list"},[_c('li',[_vm._v("Integrations (LawPay, Clio, and more!)")]),_c('li',[_vm._v("Intuitive Client Portal")]),_c('li',[_vm._v("Built-In Referral and Review Programs")])]),_c('consultation-button',{attrs:{"text":"Learn More","link":"https://www.immigrationlaw.ai/activity/ailaw/demo"}})],1),_c('div',{staticClass:"image",style:({
        backgroundImage: ("url(" + (require('../../assets/immigration/business-management.png')) + ")"),
      }),attrs:{"alt":"Business Management"}})]),_c('immigration-content')],1)}
var staticRenderFns = []

export { render, staticRenderFns }